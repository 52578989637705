import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Seo from 'components/Seo';
import HomeHero from 'components/HomeHero';
import { Container, Grid, Cell, media, List, Buttons, Button } from 'ui';
import { get } from 'lodash';
import avocado from 'images/avocado.svg';

const Section = styled.div`
  color: ${({ theme }) => theme.text};
  line-height: 1.5;
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing()};
  position: relative;
  overflow: hidden;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(4.25)} ${({ theme }) =>
    theme.spacing(3.75)};
  `}

  p {
    margin: ${({ theme }) => theme.spacing(0.5)};
  }

  h2 {
    margin: 0;
    font-weight: bold;
    font-size: 2rem;
    color: ${({ theme }) => theme.secondary};

    ${media.tablet`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    `}
  }

  ${({ variant, theme }) =>
    variant === 'main' &&
    `
    background-color: ${theme.primary};
    color: ${theme.textOnPrimaryGradient};
    
    h2 {
      color: #fff;
    }
    
    strong {
      color: #fff
    }
  `}
`;

const SectionContent = styled.div`
  column: 1;

  ${media.desktop`
    column-count: 2;
    column-gap: ${({ theme }) => theme.spacing(3.75)};
  `}
`;

const CertificationsWrapper = styled.div`
  background-color: ${({ theme }) => theme.backgroundLight};
  margin-top: -120px;
  padding-top: 120px;
  z-index: 1;

  ${props =>
    props.hasNoTopMargin &&
    `
      margin-top: 0;
      padding-top: 0;
    `}
`;

const CertificationTitle = styled.p`
  font-weight: bold;
`;

const CertificationDescription = styled.div`
  font-size: 0.875rem;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.textLight};
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing(1)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
`;

const CerticationsList = styled(List)`
  padding-left: ${({ theme }) => theme.spacing()};
  li {
    position: relative;
  }
  li:before {
    content: '•';
    color: ${({ theme }) => theme.secondary};
    position: absolute;
    left: ${({ theme }) => theme.spacing(-1)};
  }
`;

const OwnerPicture = styled.img`
  filter: greyscale(100%);
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${media.tabletMini`
  height: 282px;
  width:auto;
`}
`;

const AvocadoWrapper = styled.div`
  display: none;
  position: absolute;
  left: -50px;
  bottom: -50px;
  img {
    height: 362px;
    transform: scaleX(-1);
    transform: rotate(-45deg);
  }

  ${media.desktop`
    display: block;
  `}
`;

const IndexPage = ({ data }) => {
  const ownerInfos = get(data, 'datoCmsGeneralInfo');
  const sections = get(data, 'datoCmsHomepage.section');
  const homepage = get(data, 'datoCmsHomepage');

  return (
    <Layout>
      <Seo
        title={get(homepage, 'seo.title') || ownerInfos.ownerName}
        description={get(homepage, 'seo.description')}
        image={get(homepage, 'seo.image.url')}
        twitterCard={get(homepage, 'seo.twitterCard')}
      />
      <HomeHero hero={homepage} ownerInfos={ownerInfos} />
      <Container style={{ zIndex: 2, position: 'relative' }}>
        {sections.map(({ content, title }, index) => (
          <Section variant={Boolean(index % 2) && 'main'} key={index}>
            <Grid>
              <Cell sizes={{ default: 1 / 1, tablet: 1 / 4 }}>
                <h2>{title}</h2>
              </Cell>
              <Cell sizes={{ default: 1 / 1, tablet: 3 / 4 }}>
                <SectionContent column-count={2}>
                  <p>{content}</p>
                </SectionContent>
              </Cell>
            </Grid>
            {Boolean(index % 2) && (
              <AvocadoWrapper>
                <img src={avocado} alt="Avocado logo"></img>
              </AvocadoWrapper>
            )}
          </Section>
        ))}
      </Container>
      <CertificationsWrapper hasNoTopMargin={sections.length % 2 === 1}>
        <Container>
          <Section>
            <Grid>
              <Cell sizes={{ default: 1, tablet: 1 / 2 }} hasPaddingRight>
                <h2>Mes diplômes</h2>
                <CerticationsList>
                  {ownerInfos.diplomas.map(({ name, description, id }) => (
                    <li key={id}>
                      <CertificationTitle>{name}</CertificationTitle>
                      {description && (
                        <CertificationDescription>
                          {description}
                        </CertificationDescription>
                      )}
                    </li>
                  ))}
                </CerticationsList>
              </Cell>
              <Cell sizes={{ default: 1, tablet: 1 / 2 }} hasPaddingLeft>
                <OwnerPicture
                  src={ownerInfos.ownerPicture.url}
                  alt={ownerInfos.ownerPicture.alt ?? 'Mathieu Masse'}
                ></OwnerPicture>
                <h2>Mes distinctions</h2>
                <CerticationsList>
                  {ownerInfos.honors.map(({ name, description, id }) => (
                    <li key={id}>
                      <CertificationTitle>{name}</CertificationTitle>
                      {description && (
                        <CertificationDescription>
                          {description}
                        </CertificationDescription>
                      )}
                    </li>
                  ))}
                </CerticationsList>
              </Cell>
            </Grid>
            {Boolean(get(ownerInfos, 'calendlyLink')) && (
              <Buttons hasTopMargin>
                <Button
                  as="a"
                  href={get(ownerInfos, 'calendlyLink')}
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Prendre rendez-vous
                </Button>
              </Buttons>
            )}
          </Section>
        </Container>
      </CertificationsWrapper>
    </Layout>
  );
};
export const query = graphql`
  query {
    datoCmsHomepage {
      mainTitle
      description
      section {
        title
        content
      }
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
    datoCmsGeneralInfo {
      ownerName
      ownerPicture {
        alt
        url
      }
      calendlyLink
      honors {
        name
        id
        description
      }
      diplomas {
        name
        id
        description
      }
    }
  }
`;

export default IndexPage;
