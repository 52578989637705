import React from 'react';
import styled from 'styled-components';
import { media } from '../ui';
import avocado from '../images/avocado.svg';

const BackgroundHero = styled.div`
  position: relative;
  color: #fff;
  padding-top: 144px;
  background: ${({ theme }) => theme.primaryGradient};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    content: '';
    height: 3em;
  }

  &:before {
    bottom: 0;
    border-right: 1px solid #fff;
  }

  &:after {
    top: 100%;
    border-right: 1px solid ${({ theme }) => theme.primary};
  }
`;

const HomeHeroContainer = styled.div`
  max-width: 1000px;
  margin-left: ${({ theme }) => theme.spacing(2)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${media.desktop`
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  `}
`;

const HomeHeroTitle = styled.h1`
  font-size: 60px;
  font-weight: 800;
  line-height: 1.2;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${media.desktop`
    font-size: 90px;
  `}
`;

const OwnerName = styled.div`
  color: ${({ theme }) => theme.secondary};
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
  line-height: 1.5;
  margin-bottom: ${({ theme }) => theme.spacing()};
  border-bottom: 2px solid currentColor;
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
  white-space: nowrap;
`;

const Descriptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-wrap: wrap;
  margin-left: ${({ theme }) => theme.spacing(-2)};

  > * {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  p {
    max-width: 40rem;
    :first-child {
      margin-top: 0;
    }
  }
`;

const HalfAvocadoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-50%);
  img {
    height: 300px;
    transform: scaleX(-1);
  }
`;

const AvocadoWrapper = styled.div`
  position: absolute;
  right: -230px;
  bottom: -60px;
  transform: translateX(-50%);
  img {
    height: 362px;
  }

  ${media.desktop`
  right: 0;
  `}
`;

const HomeHero = ({ hero, ownerInfos }) => {
  const { mainTitle, description } = hero;

  return (
    <BackgroundHero>
      <HomeHeroContainer>
        <HomeHeroTitle>{mainTitle}</HomeHeroTitle>
        <Descriptions>
          <OwnerName>{ownerInfos.ownerName}</OwnerName>
          <div>
            <p>{description}</p>
          </div>
        </Descriptions>
      </HomeHeroContainer>
      <HalfAvocadoWrapper>
        <img src={avocado} alt="Avocado logo"></img>
      </HalfAvocadoWrapper>
      <AvocadoWrapper>
        <img src={avocado} alt="Avocado logo"></img>
      </AvocadoWrapper>
    </BackgroundHero>
  );
};

export default HomeHero;
